import * as React from "react";
import { Menu, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { Portal } from "react-portal";
import Button from "./button";
import DropdownItems from "./dropdownItems";

export default function (props) {

  const {
    button,
    placement,
    // select = false,
  } = props;

  const popperElRef = React.useRef(null);
  const [targetElement, setTargetElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8]
        }
      }
    ]
  });

  return (
    <div className="inline-block text-left z-20">
      <Menu>
        {({ open }) => (
          <>
            <div ref={setTargetElement}>
              <Menu.Button>
                {button || <Button>asdf</Button>}
              </Menu.Button>
            </div>

            <Portal>
              <div
                ref={popperElRef}
                style={styles.popper}
                className="z-50"
                {...attributes.popper}
              >
                <Transition
                  show={open}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                  beforeEnter={() => setPopperElement(popperElRef.current)}
                  afterLeave={() => setPopperElement(null)}
                >
                  <DropdownItems>
                    {props.children}
                  </DropdownItems>
                </Transition>
              </div>
            </Portal>
          </>
        )}
      </Menu>
    </div>
  );
}
