import React, { useEffect, useState } from 'react'
import Avatar from '../../avatar'
import { getTimeAgo } from '../../../lib/utils/misc'

export default function ChatRoomMessage(props) {
  const { message, fromMe, user } = props

  return fromMe ? (
    <div class="relative ml-auto flex w-max max-w-xs items-end justify-end space-x-3 p-1">
      <div className="flex flex-col">
        <div class="rounded-xl bg-slate-600 p-3 text-white">
          <p class="text-sm">{message.content}</p>
        </div>
        <span class="mt-1 text-right text-xs leading-none text-gray-500">
          {getTimeAgo(message.createdAt)}
        </span>
      </div>
      <Avatar user={user} width={40} className="mb-4 flex-shrink-0" />
    </div>
  ) : (
    <div class="mt-2 flex w-full max-w-xs flex-shrink-0 items-end space-x-3 p-1">
      <Avatar user={user} width={40} className="mb-4 flex-shrink-0" />
      <div className="flex flex-col">
        <div class="rounded-xl bg-gray-300 p-3">
          <p class="text-sm">{message.content}</p>
        </div>
        <span class="mt-1 text-xs leading-none text-gray-500">
          {getTimeAgo(message.createdAt)}
        </span>
      </div>
    </div>
  )
}
