import Identicon from 'react-identicons'
import getEnv from '../lib/getEnv'

export default function Avatar(props) {
  const { user, width = 40, className } = props

  if (!user) {
    return null
  }

  return (
    <div
      className={
        'items-center justify-center overflow-hidden rounded-full bg-black ' +
        className
      }
      style={{ width, height: width }}
    >
      <div className="items-center justify-center">
        {user.profile?.profilePictureKey ? (
          <img
            className="h-full rounded-full"
            src={`https://${
              getEnv() === 'prod' ? 'prod.' : 'dev.'
            }cometuploads.com/${user.profile.profilePictureKey}`}
          />
        ) : (
          <div>
            <div className="flex h-full items-center justify-center">
              <Identicon string={user.id} size={width} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
