
import withAuth from "./data/withAuth";
import Modal from "./ui/modal";
import Login from './login';

function LoginModal(props) {
  const {
    open,
    onClose,
    onLogin,
  } = props;

  return (
    <Modal
      width="sm"
      open={open}
      onClose={onClose}
      title="Log in to Comet"
    >
      <Login
        onLogin={onLogin}
      />
    </Modal>
  );
}

export default withAuth(LoginModal, { authRequired: false });
