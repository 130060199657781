import React, { useEffect, useState } from 'react'
import io from 'socket.io-client'
import Avatar from '../../avatar'
import { classnames } from 'tailwindcss-classnames'
import Button from '../button'
import ChatRoom from './chatroom'
import Collapsible from 'react-collapsible'
import ChatRoomCard from './chatroomcard'
import is from 'is_js'
import useUser from '../../../lib/data/useUser'
import withAuth from '../../data/withAuth'
import withChat from '../../data/withChat'

function ChatModal(props) {
  const {
    open,
    setOpen,
    activeRoom,
    activeRoomMessages,
    roomMessages,
    rooms,
    switchRoomById,
    sendMessage,
    user,
  } = props

  useEffect(() => {}, [open, activeRoom, roomMessages, rooms])

  return (
    <div
      className={
        'invisible fixed bottom-0 right-5 z-20 w-4/12 flex-col justify-center rounded-t-xl bg-slate-100 bg-opacity-80 shadow-2xl backdrop-blur-md md:visible' +
        (open ? ' h-96' : '')
      }
    >
      <div
        className="flex cursor-pointer flex-row items-center rounded-t-xl bg-slate-300"
        onClick={() => setOpen(!open)}
      >
        {is.empty(activeRoom) ? null : (
          <Button
            variant="transparent"
            size="sm"
            className="ml-2"
            onClick={(e) => {
              switchRoomById(null)
              e.stopPropagation()
            }}
          >
            <div className="w-min">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                class="h-5 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                />
              </svg>
            </div>
          </Button>
        )}

        <div
          className="justify-left flex cursor-pointer items-center bg-opacity-80 p-4"
          onClick={() => setOpen(!open)}
          role="button"
        >
          <div className="flex flex-row items-center px-5">
            {is.empty(activeRoom) ? (
              <div>
                <h1 className="text-xl font-bold text-slate-700">Messages</h1>
              </div>
            ) : (
              <div className="flex flex-col">
                <h1 className="text-xl font-bold text-slate-700">
                  @
                  {
                    activeRoom.CometUsers.find((cometUser) => {
                      return cometUser.id != user.id
                    }).username
                  }
                </h1>
              </div>
            )}
            <div className="fixed right-3">
              <div className={open ? 'rotate-180' : ''}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!open ? null : is.empty(activeRoom) ? (
        <div className="h-96 overflow-auto pb-16">
          {rooms.map((room) => {
            return room.lastMessageAt ? (
              // TODO: if room.unread == true -> bold the text / highlight the room card
              // room has columns: id, name, metadata, createdAt, updatedAt, lastMessageAt
              <ChatRoomCard
                switchRoomById={switchRoomById}
                room={room}
                lastMessage={roomMessages[room.id]?.[0]}
                toUser={room.CometUsers.find((cometUser) => {
                  return cometUser.id != user.id
                })}
              />
            ) : null
          })}
        </div>
      ) : (
        <div>
          <ChatRoom
            sendMessage={sendMessage}
            activeRoomMessages={activeRoomMessages}
            activeRoom={activeRoom}
            user={user}
          ></ChatRoom>
        </div>
      )}
    </div>
  )
}

export default withAuth(withChat(ChatModal))
