
import { Menu } from '@headlessui/react';
import { classnames } from 'tailwindcss-classnames';

export default function DropdownItems(props) {
  const {
    className,
  } = props;

  return (
    <Menu.Items
      static
      // className="w-56 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
      className={classnames(
        'z-50 w-56 origin-top-right border-2 border-slate-200 bg-slate-800 bg-opacity-80 backdrop-blur divide-y divide-gray-100 rounded-2xl outline-none overflow-hidden shadow-lg',
        className,
      )}
    >
      {props.children}
    </Menu.Items>
  )
}
