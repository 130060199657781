// Handles navigation and stuff

import { useEffect, useState } from 'react'

import withAuth from './data/withAuth'
import Button from './ui/button'
import Dropdown from './ui/dropdown'
import Avatar from './avatar'
import { useRouter } from 'next/router'
import {
  faGear,
  faRightToBracket,
  faWallet,
  faSignOut,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import DropdownItem from './ui/dropdownitem'

import API from '../lib/client'
import LoginModal from './loginModal'
import { getCurrentBreakpoint } from '../lib/utils/layout'
import OnboardModal from './onboardModal'
import ChatModal from './ui/chat/chatmodal'
import withChat from './data/withChat'
import Modal from './ui/modal'

function Layout(props) {
  const {
    user,
    mutateUser,
    socket,
    accessTokenStr,
    activeRoom,
    activeRoomMessages,
    roomMessages,
    rooms,
    switchRoomById,
    sendMessage,
  } = props

  const [loginModalOpen, setLoginModalOpen] = useState(false)
  const [windowWidth, setWindowWidth] = useState(null)
  const [breakpoint, setBreakpoint] = useState(null)
  const [onboardModalOpen, setOnboardModalOpen] = useState(false)

  const [iOSPromptOpen, setIOSPromptOpen] = useState(false);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const getMobileOS = () => {
    if (!isClient) {
      return 'Other';
    }

    const ua = window.navigator.userAgent;
    if (/android/i.test(ua)) {
      return "Android"
    }
    else if (/iPad|iPhone|iPod/.test(ua)
       || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
      return "iOS"
    }
    return "Other"
  }

  const Router = useRouter()

  const title = props.title ? props.title : null

  useEffect(() => {
    const getWindowWidth = () => {
      setWindowWidth(window.innerWidth)
      setBreakpoint(getCurrentBreakpoint())
    }

    getWindowWidth()

    window.addEventListener('resize', getWindowWidth)

    return () => {
      window.removeEventListener('resize', getWindowWidth)
    }
  }, []);

  useEffect(() => {
    // check localstorage if user has dismissed iOS prompt
    if (getMobileOS() === 'iOS') {
      const dismissed = localStorage.getItem('ios-prompt-dismissed');
      if (!dismissed) {
        setIOSPromptOpen(true);
      }
    }
  }, [isClient]);

  const onDismissIOSPrompt = () => {
    setIOSPromptOpen(false);
    localStorage.setItem('ios-prompt-dismissed', true);
  }

  useEffect(() => {
    // Set onboard modal open if username hasn't been set yet
    if (user && user.loggedIn) {
      if (user.profile && !user.profile.completedOnboarding) {
        setOnboardModalOpen(true)
      }
    }
  }, [user])

  const api = new API()

  const logout = async () => {
    await api.post('/auth/logout')
    mutateUser()
  }

  const onCloseLoginModal = () => {
    setLoginModalOpen(false)
  }

  const onCloseOnboardModal = () => {
    setOnboardModalOpen(false)
  }

  const onLogin = () => {
    mutateUser()
    onCloseLoginModal()
  }

  const onCloseChatModal = () => {
    setChatModalOpen(false)
  }

  const showChatModal =
    props.showChatModal !== undefined ? props.showChatModal : true

  let loggedIn = user && (user.loggedIn || user.isLoggedIn)

  const sidebar = (
    <div className="fixed bottom-0 z-10 w-full justify-center border-t bg-white md:z-10 md:h-full md:w-48 md:border-none md:pt-20">
      <ul className="m-auto flex max-w-2xl flex-row justify-around font-medium text-slate-800 md:flex-col">
        <li>
          <a
            href="/home"
            className="items-center py-2 transition duration-300 ease-in-out hover:h-full hover:bg-slate-100 hover:font-bold hover:text-slate-900 md:flex"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="my-4 h-8 w-8 md:mx-5 md:h-5 md:w-5"
            >
              <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
              <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
            </svg>

            <span className="hidden md:block">Home</span>
          </a>
        </li>
        <li>
          <a
            href="/wallet"
            className="items-center py-2 transition duration-300 ease-in-out hover:h-full hover:bg-slate-100 hover:font-bold hover:text-slate-900 md:flex"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="my-4 h-8 w-8 md:mx-5 md:h-5 md:w-5"
            >
              <path d="M2.273 5.625A4.483 4.483 0 015.25 4.5h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0018.75 3H5.25a3 3 0 00-2.977 2.625zM2.273 8.625A4.483 4.483 0 015.25 7.5h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0018.75 6H5.25a3 3 0 00-2.977 2.625zM5.25 9a3 3 0 00-3 3v6a3 3 0 003 3h13.5a3 3 0 003-3v-6a3 3 0 00-3-3H15a.75.75 0 00-.75.75 2.25 2.25 0 01-4.5 0A.75.75 0 009 9H5.25z" />
            </svg>

            <span className="hidden md:block">Wallet</span>
          </a>
        </li>
        <li>
          <a
            href="/settings/notifications"
            className="items-center py-2 transition duration-300 ease-in-out hover:h-full hover:bg-slate-100 hover:font-bold hover:text-slate-900 md:flex"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="my-4 h-8 w-8 md:mx-5 md:h-5 md:w-5"
            >
              <path
                fill-rule="evenodd"
                d="M11.828 2.25c-.916 0-1.699.663-1.85 1.567l-.091.549a.798.798 0 01-.517.608 7.45 7.45 0 00-.478.198.798.798 0 01-.796-.064l-.453-.324a1.875 1.875 0 00-2.416.2l-.243.243a1.875 1.875 0 00-.2 2.416l.324.453a.798.798 0 01.064.796 7.448 7.448 0 00-.198.478.798.798 0 01-.608.517l-.55.092a1.875 1.875 0 00-1.566 1.849v.344c0 .916.663 1.699 1.567 1.85l.549.091c.281.047.508.25.608.517.06.162.127.321.198.478a.798.798 0 01-.064.796l-.324.453a1.875 1.875 0 00.2 2.416l.243.243c.648.648 1.67.733 2.416.2l.453-.324a.798.798 0 01.796-.064c.157.071.316.137.478.198.267.1.47.327.517.608l.092.55c.15.903.932 1.566 1.849 1.566h.344c.916 0 1.699-.663 1.85-1.567l.091-.549a.798.798 0 01.517-.608 7.52 7.52 0 00.478-.198.798.798 0 01.796.064l.453.324a1.875 1.875 0 002.416-.2l.243-.243c.648-.648.733-1.67.2-2.416l-.324-.453a.798.798 0 01-.064-.796c.071-.157.137-.316.198-.478.1-.267.327-.47.608-.517l.55-.091a1.875 1.875 0 001.566-1.85v-.344c0-.916-.663-1.699-1.567-1.85l-.549-.091a.798.798 0 01-.608-.517 7.507 7.507 0 00-.198-.478.798.798 0 01.064-.796l.324-.453a1.875 1.875 0 00-.2-2.416l-.243-.243a1.875 1.875 0 00-2.416-.2l-.453.324a.798.798 0 01-.796.064 7.462 7.462 0 00-.478-.198.798.798 0 01-.517-.608l-.091-.55a1.875 1.875 0 00-1.85-1.566h-.344zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
                clip-rule="evenodd"
              />
            </svg>

            <span className="hidden md:block">Settings</span>
          </a>
        </li>
        <li>
          <a
            href="/settings"
            className="items-center py-2 transition duration-300 ease-in-out hover:h-full hover:bg-slate-100 hover:font-bold hover:text-slate-900 md:flex"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="my-4 h-8 w-8 md:mx-5 md:h-5 md:w-5"
            >
              <path
                fill-rule="evenodd"
                d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                clip-rule="evenodd"
              />
            </svg>

            <span className="hidden md:block">Profile</span>
          </a>
        </li>
      </ul>
    </div>
  )

  const buttons = [
    // <Button
    //   variant="transparent"
    //   size="lg"
    //   leftIcon={faHome}
    // >
    //   {getBreakpointValue('lg') <= windowWidth ? 'Home' : null}
    // </Button>,

    // <Button
    //   variant="transparent"
    //   size="lg"
    //   leftIcon={faUser}
    // >
    //   {getBreakpointValue('lg') <= windowWidth ? 'Profile' : null}
    // </Button>,

    // loggedIn && <Button
    //   variant="transparent"
    //   size="lg"
    //   leftIcon={faWallet}
    //   href="/wallet"
    // />,

    // loggedIn && <Button
    //   variant="transparent"
    //   size="lg"
    //   leftIcon={faGear}
    //   href="/settings"
    // />,

    <div className="grow" />,

    <h1 className="content-center text-xl font-bold text-slate-700">
      {title}
    </h1>,

    <div className="grow" />,

    loggedIn ? (
      <Dropdown
        placement="top-end"
        button={
          <Button
            variant="gradient"
            size="base"
            className="text-sm font-bold"
            style={{
              justifyContent: 'flex-start',
            }}
          >
            <Avatar user={user} width={28} className="-ml-2 mr-4" />
            <div className="-mx-1 inline-block text-left">
              <p className="font-bold leading-5">@{user.username}</p>
            </div>
          </Button>
        }
      >
        <div className="py-1">
          <DropdownItem href="/c/new" icon={faPlus}>
            New Project
          </DropdownItem>
          <DropdownItem href="/wallet" icon={faWallet}>
            Wallet
          </DropdownItem>
          <DropdownItem href="/settings" icon={faGear}>
            Settings
          </DropdownItem>
          <DropdownItem onClick={logout} icon={faSignOut}>
            Log out
          </DropdownItem>
        </div>
      </Dropdown>
    ) : (
      <Button
        variant="primary"
        size="base"
        className="font-bold"
        leftIcon={faRightToBracket}
        onClick={() => {
          setLoginModalOpen(true)
        }}
      >
        Login
      </Button>
    ),
  ]

  return (
    <div className="w-screen overflow-x-hidden">
      <Modal
        open={iOSPromptOpen}
        onClose={() => {
          setIOSPromptOpen(false)
        }}
      >
        <div className="flex flex-col items-center justify-center gap-4 p-4">
          <p className="text-center text-xl font-bold text-slate-900">
            Download the iOS App
          </p>
          <p className='text-center font-medium text-slate-600'>
            The Comet experience is better on the mobile app. Download us on
            Testflight:
          </p>
          <a
            href="https://withcomet.com/ios"
            target="_blank"
            className="w-80 p-4 bg-slate-200 rounded-2xl flex flex-row items-center gap-4 hover:bg-slate-300 cursor-pointer"
          >
            <div className="shrink-0 w-20 h-20 shadow-xl rounded-2xl bg-white flex items-center justify-center">
              <img src="/cometsquare.png" className='w-16 h-16' />
            </div>

            <div className="grow">
              <p className="text-xl font-bold text-slate-800 leading-6">
                Available on Testflight
              </p>
            </div>
          </a>

          <a
            onClick={onDismissIOSPrompt}
            className='font-bold underline cursor-pointer text-slate-500 mt-3 text-sm'
          >
            <p>Don't remind me again</p>
          </a>
        </div>
      </Modal>
      <div className="flex h-screen w-full flex-row justify-center">
        <div className="h-100 flex w-full max-w-screen-2xl items-start justify-center justify-self-center">
          <LoginModal
            open={loginModalOpen}
            onLogin={onLogin}
            onClose={onCloseLoginModal}
          />
          <OnboardModal
            open={onboardModalOpen}
            onClose={onCloseOnboardModal}
            onFinish={mutateUser}
          />

          {/* Section: Navigation */}
          {/* <div className="hidden md:inline-block h-screen overflow-y-scroll overflow-x-visible grow-0 sticky top-0 min-w-max">
            <div className="overflow-x-visible py-10 pl-2 pr-6 h-full flex flex-col items-start gap-y-1.5">
              {buttons}
            </div>
          </div> */}

          <div className="fixed top-0 z-20 flex h-20 w-full flex-col justify-center bg-slate-50 backdrop-blur-sm">
            <div className="mx-auto flex w-full max-w-full flex-row items-center justify-center gap-4 px-3">
              <div>
                <svg
                  className="ml-1"
                  width="26"
                  height="auto"
                  viewBox="0 0 371 440"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_289_67)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M77.4612 109.017C77.1399 107.923 76.7885 106.726 76.4239 105.389C74.4316 98.0824 52.378 70.1243 32.1981 44.5416C17.9741 26.5095 4.68098 9.65741 0 2.04884L90.6813 98.2694L90.5765 98.3113L167.568 179.852L209.458 224.303C212.737 227.835 216.017 231.186 219.136 234.376C233.516 249.077 244.489 260.296 236.347 269.602C226.442 280.927 213.961 269.602 199.551 249.783C192.895 240.629 187.999 233.313 183.718 226.919C178.729 219.468 174.579 213.268 169.458 206.866C168.704 205.921 167.976 205.006 167.271 204.12C157.405 191.709 152.093 185.027 141.526 187.495C137.303 188.482 132.822 191.889 129.227 196.211C126.974 199.643 124.933 203.201 123.113 206.864L123.112 206.866C116.897 219.372 113.253 233.101 112.512 247.235C111.304 270.288 117.891 293.077 131.211 311.93C144.531 330.782 163.808 344.6 185.937 351.157C208.065 357.713 231.757 356.625 253.193 348.072C261.483 344.764 269.248 340.414 276.323 335.166C294.969 321.334 320.399 313.413 340.502 325.023C360.607 336.633 367.786 362.784 351.987 379.795C333.268 399.949 310.209 415.852 284.345 426.174C245.37 441.726 202.295 443.703 162.061 431.781C121.827 419.86 86.777 394.737 62.5589 360.461C38.3407 326.184 26.3648 284.749 28.561 242.834C30.6959 202.089 46.1022 163.195 72.3759 132.068C80.5821 119.643 79.8698 117.218 77.4612 109.017ZM174.488 158.217C181.477 155.207 188.821 152.965 196.394 151.561C219.088 147.354 242.535 150.911 262.96 161.66C270.859 165.817 278.127 170.956 284.615 176.914C301.713 192.619 326.175 203.155 347.383 193.711C368.591 184.266 378.463 159.01 364.526 140.439C348.017 118.439 326.747 100.213 302.102 87.2433C264.967 67.7002 222.334 61.232 181.075 68.8808C157.784 73.1987 135.697 81.8727 115.9 94.2854L174.488 158.217Z"
                      fill="rgb(30, 41, 59)"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_289_67">
                      <rect width="371" height="439.477" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              {buttons}
              <Button
                variant="transparent"
                className="md:hidden"
                onClick={() => {
                  showChatModal //show chat modal displays on all pages except messaging page
                    ? Router.push('/chat')
                    : props.handleMessagesButtonClick()
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#334155"
                  class="h-8 w-8"
                >
                  <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z" />
                  <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z" />
                </svg>
              </Button>
            </div>
          </div>
          <div className="w-full pt-20">
            {sidebar}
            <div className="flex w-full flex-row">
              {/* Spacer to represent sidebar */}
              <div className="w-0 flex-none md:w-48"></div>
              <div className="flex-column mb-12 flex w-full flex-grow justify-center">
                <div className="h-full w-full flex-grow sm:max-w-xl">
                  {props.children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {user &&
      !!user?.id &&
      user?.loggedIn &&
      socket &&
      accessTokenStr &&
      showChatModal ? (
        <ChatModal
          activeRoom={activeRoom}
          activeRoomMessages={activeRoomMessages}
          roomMessages={roomMessages}
          rooms={rooms}
          switchRoomById={switchRoomById}
          sendMessage={sendMessage}
          user={user}
        />
      ) : null}
    </div>
  )
}

export default withAuth(withChat(Layout), { authRequired: false })
