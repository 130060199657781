
import withAuth from './data/withAuth';
import API from '../lib/client';
import Modal from './ui/modal';
import TextField from './ui/textfield';
import Button from './ui/button';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { onEnter } from '../lib/utils/misc';
import Alert from './ui/alert';

function OnboardModal(props) {
  const {
    user,
    mutateUser,

    open,
    onClose,
    onFinish,
  } = props;

  const api = new API();

  const [stage, setStage] = useState('');

  // Determine initial stage for the modal.
  useEffect(() => {
    if (user && user.profile && !user.profile.completedUsername) {
      setStage('username');
    }
  }, [user]);

  const [username, setUsername] = useState('');
  const [usernameRequestOut, setUsernameRequestOut] = useState(false);
  const [usernameRequestError, setUsernameRequestError] = useState(null);

  const onSetUsername = async () => {
    try {
      setUsernameRequestOut(true);
      setUsernameRequestError(null);

      await mutateUser(
        await api.put(
          '/auth/account',
          {
            username,
          },
        )
      );

      setUsernameRequestOut(false);
      onClose();
      onFinish();
    } catch (e) {
      setUsernameRequestError(e);
      setUsernameRequestOut(false);
    }
  }

  function renderStage(stage) {
    if (stage === 'username') {
      return {
        content: (
          <>
            <p className='text-slate-500 font-medium mb-1'>
              To get started, give yourself a username:
            </p>
            <TextField
              size="lg"
              className="my-3"
              placeholder="Username"
              onKeyPress={onEnter(onSetUsername)}

              value={username}
              disabled={usernameRequestOut}
              onChange={(e) => { setUsername(e.target.value) }}
            />
            {
              usernameRequestError && (
                <Alert>
                  {
                    usernameRequestError.response?.data?.message || 'There was an error setting your username.'
                  }
                </Alert>
              )
            }
          </>
        ),
        buttons: [
          <Button
            rightIcon={faAngleRight}
            // className="w-40"
            size="lg"
            onClick={onSetUsername}
            loading={usernameRequestOut}
          >
            Next
          </Button>
        ],
      }
    }

    return {};
  }

  const { content, buttons } = renderStage(stage);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Welcome to Comet!"
      closeable={false}
      buttons={buttons}
    >
      {content}
    </Modal>
  )
}

export default withAuth(OnboardModal, { authRequired: false });
