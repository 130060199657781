
import { Menu } from '@headlessui/react';
import { classnames } from 'tailwindcss-classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';

export default function DropdownItem(props) {
  const {
    className,
    icon,
    ...rest
  } = props;

  return (
    <Menu.Item as={Fragment}>
      {({ active }) => (
        <a
          className={classnames({
              'bg-slate-800 bg-opacity-100 text-white': active,
              'text-white': !active,
            },
            'flex items-center w-full px-4 py-3 text-md leading-5 text-left cursor-pointer font-medium',
            className,
          )}
          {...rest}
          onClick={(e) => { if (rest.onClick) rest.onClick(); e.stopPropagation(); }}
        >
          {
            icon && <FontAwesomeIcon icon={icon} className='mr-3 h-5 w-5' />
          }
          {props.children}
        </a>
      )}
    </Menu.Item>
  );
}
