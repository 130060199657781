import React, { useEffect, useState } from 'react'
import { classnames } from 'tailwindcss-classnames'
import Button from '../button'
import TextField from '../textfield'
import { onEnter } from '../../../lib/utils/misc'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons'

export default function ChatSendMessage(props) {
  const [text, setText] = useState('')

  const { sendMessage } = props

  return (
    <div class="w-2xl fixed bottom-0 z-20 flex w-full flex-row bg-slate-300 p-4">
      <TextField
        value={text}
        onChange={(e) => {
          setText(e.target.value)
        }}
        className="h-10 items-center bg-white px-3 text-sm"
        type="text"
        placeholder="Type your message…"
        onKeyPress={onEnter(() => {
          sendMessage(text)
          setText('')
        })}
        autoFocus
      />
      <Button
        className="mx-2"
        variant="primary"
        size="lg"
        onClick={() => {
          sendMessage(text)
          setText('')
        }}
        leftIcon={faPaperPlane}
      />
    </div>
  )
}
