import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import { classnames } from 'tailwindcss-classnames'
import Button from './button'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import Div100vh from 'react-div-100vh'

function Modal(props) {
  const {
    open,
    onClose,
    title,
    width = 'base',
    buttons = [],
    closeable = true,
  } = props

  const closeFunction = () => {
    if (!closeable) return
    if (onClose) onClose()
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <div className="fixed inset-0 z-30 overflow-y-auto">
        <Div100vh>
          <div className="flex min-h-full items-center justify-center px-4 pt-4 pb-20 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 h-full bg-slate-600 bg-opacity-50 backdrop-blur transition-opacity"
                onClick={closeFunction}
              />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden h-full sm:inline-block sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={classnames(
                  'relative inline-block w-full transform overflow-hidden rounded-2xl bg-white text-left align-middle align-bottom shadow-xl transition-all sm:my-24',
                  {
                    'sm:max-w-lg': width === 'lg',
                    'sm:max-w-md': width === 'base',
                    'sm:max-w-sm': width === 'sm',
                  }
                )}
              >
                <div className=" bg-white px-4 py-0 pt-2 pb-4 sm:p-6 sm:pb-4">
                  <div className="w-full sm:flex sm:items-start">
                    {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div> */}
                    <div className="mt-3 w-full sm:mt-0  sm:text-left">
                      <div className="flex w-full">
                        {title && (
                          <div
                            as="h3"
                            className="mb-3 inline-block text-lg font-medium leading-6 text-slate-900 sm:mb-0"
                          >
                            {title}
                          </div>
                        )}
                        <div className="grow" />
                        {closeable && (
                          <Transition
                            className="inline-block"
                            show={closeable}
                            enter="transition-opacity duration-75"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-75"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Button
                              leftIcon={faXmark}
                              className="-mt-1.5 -mr-1"
                              variant="transparent"
                              onClick={closeFunction}
                              disabled={!closeable}
                              tabIndex={-1}
                            />
                          </Transition>
                        )}
                      </div>
                      <div className="sm:my-3">
                        {/* Dialog contents */}
                        {props.children}
                      </div>
                    </div>
                  </div>
                </div>
                {buttons && buttons.length > 0 && (
                  <div className="flex flex-row-reverse bg-slate-100 px-4 py-4 sm:px-6">
                    {buttons}
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Div100vh>
      </div>
    </Transition.Root>
  )
}

export default Modal
