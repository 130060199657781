import React, { useEffect, useState, useRef } from 'react'
import io from 'socket.io-client'
import ChatSendMessage from './chatsendmessage'
import ChatRoomMessage from './chatroommessage'

export default function ChatRoom(props) {
  const { sendMessage, activeRoomMessages, activeRoom, user } = props

  const userIdToUser = {}
  for (const user of activeRoom.CometUsers) {
    userIdToUser[user.id] = user
  }

  const chatroomBottomRef = useRef(null)

  const scrollToBottom = () => {
    chatroomBottomRef.current.scrollIntoView()
  } // Scroll to the bottom of the chatroom when the component is mounted

  useEffect(() => {
    scrollToBottom()
  }, [activeRoomMessages.length, chatroomBottomRef.current]) // Scroll to the bottom when a new message loads

  return (
    // messages box
    <div class="mb-40 flex h-full w-full  flex-grow flex-col overflow-hidden bg-opacity-80 ">
      <div class="flex h-96 flex-grow flex-col overflow-auto pb-36">
        {activeRoomMessages.map((_, idx, arr) => (
          <ChatRoomMessage
            message={arr[arr.length - 1 - idx]}
            fromMe={arr[arr.length - 1 - idx].CometUserId == user.id}
            user={userIdToUser[arr[arr.length - 1 - idx].CometUserId]}
          />
        ))}

        <div
          style={{ float: 'left', clear: 'both' }}
          ref={chatroomBottomRef}
        ></div>
      </div>
      <ChatSendMessage sendMessage={sendMessage}></ChatSendMessage>
    </div>
  )
}
