import React, { useEffect } from 'react';
import { ChatContext } from './chatWrapper';

export default function withChat(WrappedComponent, options = {}) {
  const HOC = (props) => {
    return (
      <ChatContext.Consumer>
        {
          value => (
            value &&
              <WrappedComponent
                {...props}
                socket={value.socket}
                open={value.open}
                setOpen={value.setOpen}
                accessTokenStr={value.accessTokenStr}
                activeRoom={value.activeRoom}
                activeRoomMessages={value.activeRoomMessages}
                roomMessages={value.roomMessages}
                rooms={value.rooms}
                switchRoomById={value.switchRoomById}
                insertMessage={value.insertMessage}
                liveMessage={value.liveMessage}
                sendMessage={value.sendMessage}
                openRoomWithUser={value.openRoomWithUser}
              />
          )
        }
      </ChatContext.Consumer>
    );
  }

  HOC.getInitialProps = async (ctx) => {
    if (WrappedComponent.getInitialProps) {
      const result = await WrappedComponent.getInitialProps(ctx);
      return result;
    }
    return {};
  };

  return HOC;
}
