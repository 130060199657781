import React, { useEffect, useState } from 'react'
import Avatar from '../../avatar'
import { classnames } from 'tailwindcss-classnames'
import Collapsible from 'react-collapsible'
import { getTimeAgo } from '../../../lib/utils/misc'

export default function ChatRoomCard(props) {
  const { switchRoomById, room, lastMessage, toUser, onClick } = props

  // TODO: unread message room indicator
  // classname stuff for unread messages: "font-bold bg-slate-400"
  return (
    <div className="overflow-auto">
      <div
        className="flex cursor-pointer flex-row items-center py-6 hover:bg-slate-300 hover:bg-opacity-40"
        onClick={onClick || (() => switchRoomById(room.id))}
      >
        <div className="flex flex-row px-3">
          <div className="mr-6 w-min">
            <Avatar user={toUser} width={32} />
          </div>
          <div>
            <div className="flex flex-row items-baseline">
              <p className="font-bold leading-5">@{toUser.username}</p>{' '}
              <div class="px-2 text-xs leading-none text-gray-500">
                {lastMessage?.createdAt
                  ? `• ${getTimeAgo(lastMessage?.createdAt)}`
                  : null}
              </div>
            </div>
            <div className=" ... max-w-sm truncate text-sm">
              <p>{lastMessage?.content}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
