const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
  mode: 'jit',
  purge: ['./pages/**/*.{js,ts,jsx,tsx}', './components/**/*.{js,ts,jsx,tsx}'],
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      fontFamily: {
        // html: ['Work Sans', ...defaultTheme.fontFamily.sans],
        sans: ['Work Sans', ...defaultTheme.fontFamily.sans],
      },
      fontSize: {
        xs: '0.7rem',
        sm: '0.85rem',
        base: '0.925rem',
      },
      // fontSize: {
      //   sm: '0.85rem',
      //   base: '0.925rem',
      // },
      animation: {
        stripes: 'stripes 1s linear infinite',
      },
      keyframes: {
        stripes: {
          from: {
            backgroundPosition: '-46px 0',
          },
          to: {
            backgroundPosition: '0 0',
          },
        },
        rotate: {
          '100%': {
            transform: 'rotate(360deg)',
          },
        },
        dash: {
          '0%': {
            strokeDasharray: '1, 150',
            strokeDashoffset: '0',
          },
          '50%': {
            strokeDasharray: '90, 150',
            strokeDashoffset: '-35',
          },
          '100%': {
            strokeDasharray: '90, 150',
            strokeDashoffset: '-124',
          },
        },
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [
    require('@tailwindcss/forms'),
    require("tailwind-gradient-mask-image"),
  ],
}
